<template>
  <div class="result">
    <div class="molecule">
      <h3>Molecule Information</h3>
      <div class="description">
        <div class="molecule-info">
          <div class="descrip-item" v-for="item in targetInfo" :key="item.name">
            <div class="molecule-key">{{ item.key }}</div>
            <div class="molecule-value">{{ item.value }}</div>
          </div>
        </div>
        <div class="molecule-description">
          <div class="descrip-item" v-for="item in descriptions" :key="item.name">
            <div class="molecule-key">{{ item.key }}</div>
            <div class="molecule-value">{{ item.value }}</div>
          </div>
        </div>
      </div>
    </div>
    <VirtualTable :data="resultInfo" @getCurrentPage="getImagebyPage"></VirtualTable>
  </div>
</template>

<script>
import { historyApi } from "../api/index";
import { activityApi } from "../api/index";
import { virtualApi } from "../api/index";
import virtualTable from "../components/virtualTable.vue";
// import predictdetail from "../components/predictdetail.vue";

export default {
  props: ["resultId"],
  components: {
    VirtualTable: virtualTable,
    // PredictDetail: predictdetail,
  },
  watch: {
    formula: function (oldVal, newVal) {
      console.log(oldVal, newVal);
      this.getData();
    },
  },
  data() {
    return {
      resultData: [],
      mode: "",
      model: "",
      targetData: {},
      resultInfo: [],
      targetInfo: [
        {
          key: "Target Name",
          value: "HT-29",
        },
        { key: "Data Size", value: "1800" },
        { key: "Active Sample Amount", value: "1000" },
        {
          key: "Inactive Sample Amount",
          value: "800",
        },
      ],
      descriptions: [
        {
          key: "TN",
          value: "0.9",
        },
        { key: "TP", value: "0.81" },
        { key: "FN", value: "0.91" },
        {
          key: "FP",
          value: "0.8",
        },
        {
          key: "AUC",
          value: "0.78",
        },
        { key: "MCC", value: "0.9" },
        { key: "SE", value: "0.85" },
        {
          key: "SP",
          value: "0.9",
        },
      ],
    };
  },
  created() {
    this.getData();
  },
  methods: {
    getImagebyPage(e) {
      virtualApi
        .getmolepicture({ id: this.resultId, num: e, page: 10 })
        .then((res) => {
          let currentsmilesname = Object.keys(res.data.result);
          for (let i = 0; i < currentsmilesname.length; i++) {
            this.resultInfo[10 * e - 10 + i]["img"] =
              "data:image/png;base64," + res.data.result[currentsmilesname[i]];
          }
        });
    },
    getTargetData() {
      return new Promise((resolve, reject) => {
        activityApi
          .getDetailModelInfo({ mode: this.mode, model: this.model }) //先获取分子信息 (直接输入分子式)
          .then((res) => {
            if (res.code == 200) {
              console.log("获取模型详细信息", res.data);
              resolve(res.data);
            } else {
              reject(res.msg);
            }
          })
          .catch((err) => {
            this.$store.commit("alertMsg", {
              msg: err,
              type: "error",
            });
            reject(err);
          });
      });
    },
    getResultData() {
      return new Promise((resolve, reject) => {
        historyApi
          .getResultById(this.resultId) //先获取分子信息 (直接输入分子式)
          .then((res) => {
            if (res.code == 200) {
              console.log("获取结果信息", res.data);
              resolve(res.data);
            } else {
              reject(res.msg);
            }
          })
          .catch((err) => {
            this.$store.commit("alertMsg", {
              msg: err,
              type: "error",
            });
            reject(err);
          });
      });
    },
    // 获取预测结果
    getData() {
      this.getResultData()
        .then((res) => {
          [this.mode, this.model] = res.chosen_category_or_target.split(" ");
          let formulas = res["output_score"].match(/'(.*?)'/g).map((item) => {
            return item.replace(/'/g, "");
          });
          let scores = res["output_score"].match(/:(.*?)(,|})/g).map((item) => {
            item = item.replace(/(,|})/g, "");
            return item.replace(/: /g, "");
          });
          this.getTargetData().then((res) => {
            this.modelInfo = res;
            this.dealWithData(formulas, scores);
          });
        })
        .catch((err) => {
          this.$store.commit("alertMsg", {
            msg: err,
            type: "error",
          });
        });
    },
    dealWithData(formulas, scores) {
      this.targetInfo[0].value = this.modelInfo["name"];
      this.targetInfo[1].value = this.modelInfo["total_count"];
      this.targetInfo[2].value = this.modelInfo["activity"];
      this.targetInfo[3].value = this.modelInfo["inactivity"];
      this.descriptions[0].value = this.modelInfo["test_tn"];
      this.descriptions[1].value = this.modelInfo["test_tp"];
      this.descriptions[2].value = this.modelInfo["test_fn"];
      this.descriptions[3].value = this.modelInfo["test_fp"];
      this.descriptions[4].value = this.modelInfo["test_auc"].toFixed(2);
      this.descriptions[5].value = Number(this.modelInfo["test_mcc"]).toFixed(2);
      this.descriptions[6].value = this.modelInfo["test_BA"].toFixed(2);
      this.descriptions[7].value = this.modelInfo["test_auc"].toFixed(2);

      for (let i = 0; i < formulas.length; i++) {
        let item = {
          smile: formulas[i],
          score: scores[i],
          img: "",
        };
        this.resultInfo.push(item);
      }
      this.getImagebyPage(1);
    },
  },
  updated() {
    // this.getData();
  },
  computed: {},
};
</script>


<style scoped>
.result {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  overflow: hidden;
}

.molecule {
  color: black;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 110px;
  border-radius: 10px;
  background: white;
}

.molecule h3 {
  box-sizing: border-box;
  text-align: center;
  font-weight: lighter;
  width: 100%;
  padding: 10px;
  font-size: 20pt;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  background: linear-gradient(to right,
      var(--color2-dark),
      var(--color2-light));
}

.molecule .description {
  display: flex;
}

.molecule img {
  width: 100%;
  height: 100%;
  /* border: 1px solid black; */
  object-fit: contain;
}

.description {
  width: 800px;
}

.molecule-info {
  display: flex;
  width: 600px;
  height: 300px;
  flex-wrap: wrap;
  flex: 5;
  flex-direction: column;
  border-right: 1px solid #e9e9e9;
}

.molecule-description {
  display: flex;
  width: 600px;
  height: 300px;
  flex-wrap: wrap;
  flex: 3;
  flex-direction: column;
}

.descrip-item {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  height: 25%;
  text-align: center;
}

.descrip-item:nth-child(2n) {
  background: var(--color2-light);
}

.molecule-key {
  flex: 1;
}

.molecule-value {
  flex: 1;
  overflow: hidden;
  text-overflow: ellipsis;
  word-break: break-all;
}

.molecule-detail .icon p {
  position: absolute;
  background-color: rgba(0, 0, 0, 0.8);
  color: white;
  left: 50%;
  transform: translateX(-50%);
  bottom: 30px;
  white-space: nowrap;
  display: none;
  padding: 5px;
  font-size: 15px;
  font-style: normal;
}

.pointer {
  display: none;
  width: 0;
  height: 0;
  border-top: 10px solid rgba(0, 0, 0, 0.8);
  border-bottom: 10px solid rgba(0, 0, 0, 0);
  border-left: 20px solid rgba(0, 0, 0, 0);
  border-right: 20px solid rgba(0, 0, 0, 0);
  position: absolute;
  bottom: 10px;
  left: 50%;
  transform: translateX(-50%);
}

.molecule-detail .icon {
  font-size: 20px;
  cursor: pointer;
}

.molecule-detail .icon:hover p,
.molecule-detail .icon:hover .pointer {
  display: block;
}

.kongban-section {
  display: flex;
  justify-content: space-between;
  width: 1200px;
  height: 380px;
  margin: 150px 0;
  position: relative;
}

.color-item {
  display: flex;
  position: relative;
}

.color-bar {
  width: 20px;
  height: 80px;
}

.color-item:nth-child(1) {
  background: linear-gradient(to bottom, rgb(182, 41, 34), rgb(210, 69, 54));
}

.color-item:nth-child(2) {
  background: linear-gradient(to bottom, rgb(226, 131, 55), rgb(239, 182, 77));
}

.color-item:nth-child(3) {
  background: linear-gradient(to bottom, rgb(70, 150, 80), rgb(106, 221, 128));
}

.color-score {
  top: 30%;
  left: 150%;
  position: absolute;
  text-align: center;
  color: white;
  font-size: 15px;
  line-height: 100%;
  white-space: nowrap;
}

.kongban {
  flex: 1;
  transform-style: preserve-3d;
}

.selector {
  max-height: 100%;
  width: 160px;
}

.menu-title {
  border-radius: 4px;
  height: 40px;
  font-size: 18pt;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 10px;
  background: linear-gradient(to right,
      var(--color2-dark),
      var(--color2-light));
  cursor: pointer;
}

.menu-title .el-icon {
  position: relative;
  font-size: 22px;
  transition: 300ms ease-in;
  color: var(--color1-dark);
}

.menu-title .el-icon.show {
  transform: rotate(180deg);
}

.menu-items {
  height: 100%;
  background: white;
  overflow: scroll;
  padding: 0 15px;
  margin: 1px 0;
  transition: 300ms ease-in-out;
}

.menu-items.not-show {
  height: 0;
}

.menu-item {
  height: 40px;
  margin: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid var(--color2-dark);
  cursor: pointer;
}

.menu-item:hover {
  background: var(--color2-light);
}

.menu-item .el-icon {
  display: none;
}

.menu-item.selected .el-icon {
  font-size: 20px;
  color: var(--color1-light);
  display: inline-block;
}
</style>
